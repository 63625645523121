import * as React from "react";
import { Link } from "gatsby";

import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function BurnOut() {
  return (
    <Layout>
      <Helmet title="Burn Out | Michael Weslander" />

      <h1>Burn Out</h1>

      <h2>Michael Weslander</h2>

      <h4>Key: A</h4>

      <h4>Tempo: (6/8) 70</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <Ch>F#m</Ch>I’ve lost my connection
          </p>
          <p>
            <Ch>A</Ch>Lost my overdrive
          </p>
          <p>
            <Ch>D</Ch>Lost myself promoting
          </p>
          <p>
            <Ch>Bm</Ch>Someone else online
          </p>
        </Verse>
        <Verse>
          <p>
            <Ch>F#m</Ch>Lost in streams of data
          </p>
          <p>
            <Ch>A</Ch>Scrolling through my mind
          </p>
          <p>
            <Ch>D</Ch>Losing sleep on my own
          </p>
          <p>
            <Ch>Bm</Ch>Messed up time
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>A</Ch>How hard can it <Ch>Em</Ch>be
          </p>
          <p>
            To <Ch>D</Ch>move a burn from the <Ch>A</Ch>heat
          </p>
          <p>
            The <Ch>F#m</Ch>flames always seem to dance <Ch>B</Ch>higher
          </p>
          <p>
            When I'm<Ch>D</Ch> in need<Ch>E</Ch>
          </p>
        </Chorus>
        <Verse>
          <p>
            <Ch>F#m</Ch>Lost in crunching numbers
          </p>
          <p>
            <Ch>A</Ch>Accounts in under flow
          </p>
          <p>
            <Ch>D</Ch>Work a little harder
          </p>
          <p>
            <Ch>Bm</Ch>Just to watch it go
          </p>
        </Verse>
        <Verse>
          <p>
            <Ch>F#m</Ch>Lost my sense of purpose
          </p>
          <p>
            <Ch>A</Ch>Saving up for when
          </p>
          <p>
            <Ch>D</Ch>I can pay a fortune
          </p>
          <p>
            <Ch>Bm</Ch>To be young again
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>A</Ch>How hard can it <Ch>Em</Ch>be
          </p>
          <p>
            To <Ch>D</Ch>move a burn from the <Ch>A</Ch>heat
          </p>
          <p>
            The <Ch>F#m</Ch>flames always seem to shine <Ch>B</Ch>brighter
          </p>
          <p>
            When I'm<Ch>D</Ch> in need<Ch>E</Ch>
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
